.header {
  background-color: #373334;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  max-height: 50rem;
  box-sizing: border-box;
}

.header a {
  text-decoration: none;
}

.logo {
  display: none;
}

/*Burger*/

.hidden {
  display: none;
}

.nav-links {
  color: #f1f1f0;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  flex-direction: column;
}

.navbar__items {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconDisp {
  display: none!important;
}

.navbar__items__redes {
  display: flex;
  margin-left: 15px;
}

.burger-container {
  cursor: pointer;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.burger,
.burger::before,
.burger::after {
  width: 1.5rem;
  height: 0.15rem;
  border-radius: 1rem;
  background-color: #373334;
  position: relative;
  z-index: 700;
  transition: 0.5s;
}

.burger::before {
  content: "";
  position: absolute;
  top: 0.5rem;
}

.burger::after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
}

.burger-branding,
.burger-branding::before,
.burger-branding::after {
  background-color: white;
}

.burger-close {
  background-color: white;
  transition: 0.5s;
  transform: rotate(135deg);
  top: 0;
}

.burger-close::before,
.burger-close::after {
  transform: rotate(90deg);
  top: 0;
  background-color: white;
}

.navbar-responsive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: #373334;
  clip-path: circle(0.3% at 100% 0);
  transition: all 0.5s;
  pointer-events: none;
}

.open {
  clip-path: circle(141.4% at 100% 0);
  pointer-events: all;
}

.navbar-responsive a {
  color: white;
  margin-bottom: 2rem;
}

.navbar-responsive .nav-links {
  font-size: 1.25rem;
}

.submenu {
  font-size: 1rem;
}

.navbar-responsive a {
  margin-right: 0;
}

.logoRes {
  width: 8rem;
}

.logo-link {
  z-index: 2000;
  cursor: pointer;
}

.submenu {
  position: unset;
  top: 58px;
  background-color: #373334;
  color: #fff;
  text-transform: uppercase;
  list-style: none;
  width: 180px;
  box-sizing: content-box;
  display: row;
  text-align: center;
  transform: translateX(0px);
}

.nav-links:hover > .submenu {
  display: block;
}

.submenu__item {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .header {
    background-color: #373334;
    position: sticky;
    top: -1px;
    z-index: 1000;
    left: 0;
    width: 100%;
    transition: 1s;
    max-height: 50rem;
  }

  .burger-container {
    display: none;
  }

  .submenu__item {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .submenu {
    display: none;
    position: absolute;
    text-align: left;
    transform: translateX(-30px);
    font-size: 1rem;
  }

  .navbar-responsive {
    flex-direction: row;
    position: static;
    display: flex;
    height: max-content;
    clip-path: none;
    justify-content: space-between;
  }

  .navbar-responsive a {
    color: white;
    margin: 0;
    transition: all 0.5s;
  }

  .navbar-responsive .nav-links {
    font-size: 1rem;
    font-weight: 500;
  }

  .navbar-responsive a:hover {
    color: #ed4a23;
    font-weight: 600;
  }

  .navbar-responsive  {
    border: none;
    margin: 0;
    /* margin-right: 40%; */
  }

  .navbar__items {
    flex-direction: row;
  }

  .logoRes {
    width: 20rem;
  }

  .nav-links {
    flex-direction: row;
    padding: 0.5rem 0 0.5rem 2rem;
  }

  .logo {
    display: block;
    width: 8rem;
  }

  .navbar-responsive {
    pointer-events: all;
  }

}


