.footer-container {
  background: #373334;
  display: flex;
  color: #f1f1f0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  gap: 10%;
  align-items: flex-start;
}

.footer-container h2 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

.footer-form {
  width: 50%;
}

.footer-contacto-horarios {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.footer-contacto {
  width: auto;
  display: flex;
  flex-direction: column;
}

.footer-contacto a {
  text-decoration: none;
  font-size: 1.2rem;
  margin: 3% 0 4%;
  color: #f1f1f0;
}

.footer-horarios {
  width: auto;
}

.footer-horarios p {
  font-size: 1.2rem;
  padding-bottom: 1rem;
}

.footer-horarios a {
  font-size: 1.2rem;
  color: #f1f1f0;
}

.footer-section-end {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
}

.footer-link-items__redes {
  display: flex;
  gap: 1rem;
}

.footer {
  background: #373334;
  color: #f1f1f0;
  padding-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .footer-form {
    width: 80%;
  }

  .footer-contacto-horarios {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .footer-container {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .footer-form {
    width: 100%;
  }

  .footer-container h2 {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .footer-contacto a {
    text-align: center;
  }

  .footer-contacto-horarios {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .footer-contacto {
    width: 100%;
  }
  
  .footer-horarios {
    width: 100%;
    text-align: center;
  }

  .footer-link-items__redes {
    justify-content: center;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .footer-container h2 {
    font-size: 1.8rem;
  }

  .footer-contacto a {
    font-size: 1rem;
  }

  .footer-horarios p {
    font-size: 1rem;
  }
}
