.form-inputs, .form-input, .form-label{
  background:transparent;
  color:#F1F1F0;
  resize: none;
}
.form-label{
  font-size: 1.4rem;
}
.form-row{
  display: flex;
  margin-top: 2rem;
  gap: 3rem;
}

.form-inputs{
  display: grid;
  width: 100%; 
}

.form-input{
  font-size: 1rem;
  min-height: 28px;
  border: none;
  border-bottom: 1px solid #F1F1F0;
  outline: none;
}

.textarea {
  margin-top: 6px;
}

.form-input:focus{
  border-bottom: 2px solid #ED4A23;   
}

select option{
  background:#373334;
  color:#F1F1F0;
}

.form-input-btn{
  border: 1px solid #F1F1F0;
  background: transparent;
  color: #F1F1F0;
  font-size: 1.5rem;
  padding: 7px 13px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  transition: ease-in-out .25s;
}

.form-input-btn:hover{
  background: #ED4A23; 
}

#form-contain-btn{
  display: block;
  text-align: right;
}

.input-error{
  color: red;
  font-size: 13px;
  padding: 2px 0;
  position: absolute;
  margin-top: 4rem;
  font-weight: 400;
  font-style: italic;
  animation-name: error;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  font-family: 'Montserrat';
}

@keyframes error{
  from{
    text-shadow: none;
  }
  to{
    text-shadow: 1px 1px 7px black;
  }
}

#comment ~ .input-error{
  margin-top: 6rem;
  padding: 0px 0;
}

@media screen and (max-width: 960px) {
  .form-inputs {
    display: block;
  }
  
  .form-input {
    width: 100%;
  }

  .form-label {
    font-size: 1.2rem;
  }

  .input-error {
    position: initial;
  }
}

@media screen and (max-width: 600px) {
  .form-row {
    gap: 2rem;
    flex-direction: column;
  }    
}