.carousel_shadow {
  filter: drop-shadow(5px 5px 10px #D4D4D4);
}

.carousel .control-dots .dot.selected {
  background: #ed4a23;
  outline: none;
}

.carousel.carousel-slider {
  /* margin-top: 100px; */
  /* margin: 100px 0px 0px 0px!important; */
  background-color: #f1f1f0;
}

.slider-redes {
  width: 20%;
  position: absolute;
  bottom: 10%;
  left: 5%;
  z-index: 2;
}

li.slider {
  text-decoration: none;
  outline: none;
}

.slider video {
  width: 100%;
  height: auto;
  outline: none;
  border: none;
  text-decoration: none;
}

.slider-info {
  color: #ed4a23;
  font-size: 2rem;
  font-weight: 700;
  outline: none;
  user-select: none;
  white-space: nowrap;
}

.slider-button {
  color: #ffffff;
  background: #ed4a23;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  font-size: 1.5rem;
  padding: 5px 15px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  z-index: 5;
}

.slider-icon-redes {
  margin-top: 2rem;
  padding-left: 9%;
}

.slider-icon-redes a {
  color: #ed4a23;
  font-size: 1.5rem;
  text-decoration: none;
  outline: none;
  margin-right: 10%;
}

.carousel-slider .control-dots {
  bottom: 15px !important;
}

@media screen and (max-width: 960px) {
  .slider-redes {
    width: 100%;
    position: absolute;
    display: flex;
    z-index: 2;
    bottom: 0%;
    top: 28%;
    left: 10%;
  }

  .slider-info {
    font-size: 1.5rem;
  }

  .slider-button {
    font-size: 1.2rem;
    padding: 5px 12px;
  }

  .slider-icon-redes {
    display: none;
  }

  .carousel .control-dots {
    margin: 5px 0;
  }

  .carousel-slider .control-dots {
    bottom: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .slider-info {
    font-size: 1rem;
  }
}