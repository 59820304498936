.cards__container {
  display: flex;
  flex-flow: column;
  margin-top: 75px;
}

.cards__items {
  margin-bottom: 10%;
  position: relative;
  gap: 30px;
  filter: drop-shadow(5px 5px 5px #bbbbbb);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}

.cards__item__link {
  height: fit-content;
  color: #d54320;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  background: #f1f1f0;
  border-radius: 8px;
  padding: 0.8rem 1.2rem;
  width: 9rem;
  text-align: center;
  text-decoration: none;
}

.cards__item__link i {
  font-weight: 600;
}

.cards__item__link:hover {
  color: #f1f1f0;
  background: #ed4a23;
  border: 1px solid #f1f1f0;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  max-height: 290px;
  height: 15vw;
  overflow: hidden;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 15px 15px;
  max-width: calc((100%) - 60px);
  font-size: 35px;
  font-weight: bolder;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__video {
  cursor: pointer;
}

.cards__item__zoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: ease-in-out 0.25s;
  cursor: pointer;
  animation: zoom-in-out 5s infinite;
}

@keyframes zoom-in-out {
  0% {
    scale: 1;
    filter: brightness(0.9);
  }

  50% {
    scale: 1.1;
    filter: brightness(1);
  }

  100% {
    scale: 1;
    filter: brightness(0.9);
  }
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: ease-in-out 0.25s;
  filter: brightness(0.9);
  cursor: pointer;
}

.tag {
  position: absolute;
  top: 0;
  transform: translate(-15%, -50%);
  z-index: 100;
}

.cards__item__data {
  display: flex;
  align-items: center;
  margin-left: 0.7vw;
  width: max-content;
}

.cards__item__data__icon {
  width: 1vw;
  height: 1vw;
  margin-right: 0.2vw;
}

.cards__title__model {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f1f1f0;
  white-space: nowrap;
}

.cards__item__data__text {
  color: #f1f1f0;
  font-size: 0.75rem;
}

.cards__item__line {
  border-bottom: 0px solid #f1f1f0;
  border-color: #f1f1f0;
  box-shadow: none;
  margin: 15px 0;
  border-style: solid;
  width: 100%;
}

.cards__item__line:last-of-type {
  margin-bottom: 5px;
}

.cards__item__img:hover {
  transform: scale(1.1);
  filter: brightness(1);
}

.cards__item__info {
  padding: 1.25rem;
  background: #ed4a23;
  display: block;
  width: auto;
  height: 262px;
}

.cards__item__prince__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.cards__item__prince__item:first-child {
  width: 100%;
}

.cards__item__prince__item img {
  width: 15px;
}

.cards__item__prince__item span {
  font-size: 11px;
  color: #fff;
  width: max-content;
}

.cards__item__display {
  display: flex;
  gap: 4px;
  justify-content: space-between;
}

.cards__item__prince {
  display: flex;
  padding: 2% 0px;
  justify-content: space-between;
}

.cardLine {
  color: #f1f1f0;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
  user-select: none;
  background-color: #373334;
  width: 60%;
  margin: auto;
  padding: 15px;
  position: relative;
}

.rectangulo {
  height: 54px;
  width: 180px;
  border: 5px solid #d54320;
  position: absolute;
  z-index: -1;
  right: -20px;
  top: -15px;
}

.rectangulo-cabana {
  border: 10px solid #373334;
  height: 84px;
  width: 200px;
  right: -30px;
  top: -30px;
}

.cardLineSubText {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  color: #373334;
  user-select: none;
  margin-top: 15px;
  margin-bottom: 40px;
}

.lg .lg-img-wrap {
  background: #373334;
}

div.lg-thumb,
div#lg-counter,
span#lg-counter-current,
span#lg-counter-all {
  background: transparent;
  color: #f1f1f0;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #ed4a23;
}

a#lg-download {
  display: none;
}

.cards__item__info__prince {
  color: #f1f1f0;
}

.cards__item__entrega {
  font-size: 15px;
  font-weight: 400;
}

.cards__item__entrega strong {
  font-size: 20px;
}

.cards__item__cuotas {
  font-size: 15px;
  font-weight: 400;
}

.fadeOutImage {
  animation: fadeIn 0.9s ease reverse;
  opacity: 0;
}

.cards__item__display_icon {
  display: flex;
}

.linea-cabana {
  background-color: #E64C2A;
  justify-content: flex-end;
  position: relative;
  padding-right: 65px;
  width: 85% !important;
  font-size: 2.35vw;
}

.oferta-lanzamiento {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 20px;
  transform: translateY(-30%);
}

.contenedor-complejo {
  display: grid;
  grid-template-columns: 1fr 32%;
}

.contenedor-complejo {
  background-color: #E54B2A;
}

.contenedor-complejo img {
  height: 100%;
  object-fit: cover;
}

.contenedor-complejo__enunciado {
  padding: 0 33px;
  padding-top: 31px;
}

.contenedor-complejo__enunciado h1 {
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44.6701px;
  line-height: 57px;
  text-transform: uppercase;
  margin-bottom: 18px;

  color: #F1F1F0;
}


.contenedor-complejo__enunciado p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  padding-top: 20px;
  border-top: 2px solid #F2F0F2;
}

.contenedor-complejo__enunciado__box {
  color: #000;
  background-color: #fff;
  box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  -webkit-box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  -moz-box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  padding: 8px 15px;
  transform: translateX(-6px);
  margin-top: 43px;
}

.contenedor-complejo__enunciado__box p {
  color: #000;
  padding: 0;
  border: none;
}

.items__complejo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 43px;
}

.cardLineSubText-cabana {
  margin-top: 67px;
  margin-bottom: 90px;
}

@media screen and (max-width: 1600px) {
  .cards__item__link {
    display: flex;
    width: 8rem;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .cards__item__info {
    height: 255px;
  }

  .oferta-lanzamiento {
    width: 370px;
    top: -10px;
    left: 3%;
  }

  .linea-cabana {
    justify-content: center;
    margin-top: 78px;
    padding-right: 0;
  }

  .cards__item__info {
    height: 290px;
  }
}

@media screen and (max-width: 1500px) {
  .cards__item__pic-wrap {
    height: 17rem;
  }

  .cards__item__info {
    height: 290px;
  }

}

@media screen and (max-width: 1300px) {
  .cards__item__pic-wrap {
    height: 15rem;
  }
}

@media screen and (max-width: 1200px) {
  .cards__title__model {
    font-size: 1.25rem;
  }

  .cards__item__entrega {
    display: flex;
    flex-direction: column;
  }

  .cards__item__info {
    height: 310px;
  }
}

@media screen and (max-width: 1150px) {
  .cards__item__link {
    padding: 0.8rem 0.2rem;
    width: 8rem;
  }

  .cards__item__pic-wrap {
    height: 13rem;
  }


}

@media screen and (max-width: 1000px) {
  .cards {
    padding: 0rem;
  }

  .cabanas-items {
    padding: 38px 27px;
  }

  .cards__item__pic-wrap {
    width: 25rem;
    height: 60rem;
  }

  .cards__item__data__text {
    font-size: 1rem;
  }

  .cards__item__data__icon {
    width: 1.4rem;
    height: 2rem;
    margin-right: 1.5vw;
  }

  .cards__item {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 50px;
    margin: 2rem 0px;
    display: block;
    width: 100%;
    position: relative;
  }

  .cardLineSubText-cabana {
    margin-top: 34px;
    margin-bottom: 26px;
  }

  .cards__items {
    display: flex;
    overflow-x: auto;
  }

  .cards__item__entrega {
    display: flex;
    flex-direction: row;
  }

  .cards__item__entrega strong {
    margin-left: 0.25rem;
  }

  .cardLine {
    width: 75%;
  }

  .contenedor-complejo {
    grid-template-columns: auto;
  }

  .oferta-lanzamiento {
    width: 180px;
    top: 75px;
    left: 30px;
  }

  .linea-cabana {
    margin-top: 115px;
  }

  .contenedor-complejo__enunciado {
    padding: 25px 18px;
  }

  .contenedor-complejo__enunciado h1 {
    font-size: 24px;
    margin: 0;
  }

  .contenedor-complejo__enunciado p:first-of-type {
    padding-top: 10px;
  }

  .contenedor-complejo__enunciado p:nth-child(1) {
    padding-top: 0;
  }

  .items__complejo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }

  .linea-cabana {
    font-size: 2.4vw;
    justify-content: center;
  }

  .rectangulo-cabana {
    border: 8px solid #373334;
    height: 60px;
    width: 160px;
    right: -20px;
    top: -18px;
  }

  .cards__item__info {
    height: 270px;
  }
}

@media screen and (max-width: 450px) {
  .cards__container {
    margin-top: 50px;
  }

  .cardLineSubText-cabana {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .cards__title__model {
    font-size: 1.6rem;
  }

  .cards__item__pic-wrap {
    width: 100%;
    height: 12.6rem;
  }

  .cards__item__display {
    flex-direction: column;
    align-items: center;
  }

  .cards__item__prince {
    flex-direction: column;
    align-items: center;
  }

  .cards__item__info__prince {
    text-align: center;
  }

  .cards__item__info {
    width: 17rem;
    height: 400px;
  }

  .cards__item__prince__item:last-of-type {
    margin-top: 8px;
  }

  .cards__item__link {
    margin-top: 1rem;
    padding: 0.8rem 2.5rem;
    width: 11rem;
  }

  .cardLine {
    font-size: 6vw;
    width: 75%;
  }

  .cardLineSubText {
    font-size: 4vw;
    margin: 0;
    margin-top: 10px;
  }

  .cardLineSubText-cabana {
    margin-top: 28px;
    margin-bottom: 26px;
  }

  .cards__item__data {
    margin-left: 4vw;
  }

  .rectangulo {
    width: 120px;
  }

  .linea-cabana {
    font-size: 3.4vw;
    justify-content: center;
  }

  .cards__item__prince__item:first-child {
    width: initial;
  }

}