::selection {
  background: #f1f1f0;
  color: #ed4a23;
}

.wrapper {
  padding-right: 15vw;
  padding-left: 15vw;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

body {
  background-color: #f1f1f0;
}

/* @media screen and (min-width: 900px) {
  img.lg-object.lg-image {
    padding: 8rem;
  }
} */

@media screen and (max-width: 1500px) {
  .wrapper {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

.header {
  background-color: #373334;
  padding: 1rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  max-height: 50rem;
  box-sizing: border-box;
}

.header a {
  text-decoration: none;
}

.logo {
  display: none;
}

/*Burger*/

.hidden {
  display: none;
}

.nav-links {
  color: #f1f1f0;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  flex-direction: column;
}

.navbar__items {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconDisp {
  display: none!important;
}

.navbar__items__redes {
  display: flex;
  margin-left: 15px;
}

.burger-container {
  cursor: pointer;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.burger,
.burger::before,
.burger::after {
  width: 1.5rem;
  height: 0.15rem;
  border-radius: 1rem;
  background-color: #373334;
  position: relative;
  z-index: 700;
  transition: 0.5s;
}

.burger::before {
  content: "";
  position: absolute;
  top: 0.5rem;
}

.burger::after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
}

.burger-branding,
.burger-branding::before,
.burger-branding::after {
  background-color: white;
}

.burger-close {
  background-color: white;
  transition: 0.5s;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  top: 0;
}

.burger-close::before,
.burger-close::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 0;
  background-color: white;
}

.navbar-responsive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: #373334;
  -webkit-clip-path: circle(0.3% at 100% 0);
          clip-path: circle(0.3% at 100% 0);
  transition: all 0.5s;
  pointer-events: none;
}

.open {
  -webkit-clip-path: circle(141.4% at 100% 0);
          clip-path: circle(141.4% at 100% 0);
  pointer-events: all;
}

.navbar-responsive a {
  color: white;
  margin-bottom: 2rem;
}

.navbar-responsive .nav-links {
  font-size: 1.25rem;
}

.submenu {
  font-size: 1rem;
}

.navbar-responsive a {
  margin-right: 0;
}

.logoRes {
  width: 8rem;
}

.logo-link {
  z-index: 2000;
  cursor: pointer;
}

.submenu {
  position: unset;
  top: 58px;
  background-color: #373334;
  color: #fff;
  text-transform: uppercase;
  list-style: none;
  width: 180px;
  box-sizing: content-box;
  display: row;
  text-align: center;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.nav-links:hover > .submenu {
  display: block;
}

.submenu__item {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .header {
    background-color: #373334;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 1000;
    left: 0;
    width: 100%;
    transition: 1s;
    max-height: 50rem;
  }

  .burger-container {
    display: none;
  }

  .submenu__item {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .submenu {
    display: none;
    position: absolute;
    text-align: left;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    font-size: 1rem;
  }

  .navbar-responsive {
    flex-direction: row;
    position: static;
    display: flex;
    height: -webkit-max-content;
    height: max-content;
    -webkit-clip-path: none;
            clip-path: none;
    justify-content: space-between;
  }

  .navbar-responsive a {
    color: white;
    margin: 0;
    transition: all 0.5s;
  }

  .navbar-responsive .nav-links {
    font-size: 1rem;
    font-weight: 500;
  }

  .navbar-responsive a:hover {
    color: #ed4a23;
    font-weight: 600;
  }

  .navbar-responsive  {
    border: none;
    margin: 0;
    /* margin-right: 40%; */
  }

  .navbar__items {
    flex-direction: row;
  }

  .logoRes {
    width: 20rem;
  }

  .nav-links {
    flex-direction: row;
    padding: 0.5rem 0 0.5rem 2rem;
  }

  .logo {
    display: block;
    width: 8rem;
  }

  .navbar-responsive {
    pointer-events: all;
  }

}



.footer-container {
  background: #373334;
  display: flex;
  color: #f1f1f0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  grid-gap: 10%;
  gap: 10%;
  align-items: flex-start;
}

.footer-container h2 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

.footer-form {
  width: 50%;
}

.footer-contacto-horarios {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.footer-contacto {
  width: auto;
  display: flex;
  flex-direction: column;
}

.footer-contacto a {
  text-decoration: none;
  font-size: 1.2rem;
  margin: 3% 0 4%;
  color: #f1f1f0;
}

.footer-horarios {
  width: auto;
}

.footer-horarios p {
  font-size: 1.2rem;
  padding-bottom: 1rem;
}

.footer-horarios a {
  font-size: 1.2rem;
  color: #f1f1f0;
}

.footer-section-end {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
}

.footer-link-items__redes {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.footer {
  background: #373334;
  color: #f1f1f0;
  padding-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .footer-form {
    width: 80%;
  }

  .footer-contacto-horarios {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .footer-container {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .footer-form {
    width: 100%;
  }

  .footer-container h2 {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .footer-contacto a {
    text-align: center;
  }

  .footer-contacto-horarios {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .footer-contacto {
    width: 100%;
  }
  
  .footer-horarios {
    width: 100%;
    text-align: center;
  }

  .footer-link-items__redes {
    justify-content: center;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .footer-container h2 {
    font-size: 1.8rem;
  }

  .footer-contacto a {
    font-size: 1rem;
  }

  .footer-horarios p {
    font-size: 1rem;
  }
}

.form-inputs, .form-input, .form-label{
  background:transparent;
  color:#F1F1F0;
  resize: none;
}
.form-label{
  font-size: 1.4rem;
}
.form-row{
  display: flex;
  margin-top: 2rem;
  grid-gap: 3rem;
  gap: 3rem;
}

.form-inputs{
  display: grid;
  width: 100%; 
}

.form-input{
  font-size: 1rem;
  min-height: 28px;
  border: none;
  border-bottom: 1px solid #F1F1F0;
  outline: none;
}

.textarea {
  margin-top: 6px;
}

.form-input:focus{
  border-bottom: 2px solid #ED4A23;   
}

select option{
  background:#373334;
  color:#F1F1F0;
}

.form-input-btn{
  border: 1px solid #F1F1F0;
  background: transparent;
  color: #F1F1F0;
  font-size: 1.5rem;
  padding: 7px 13px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  transition: ease-in-out .25s;
}

.form-input-btn:hover{
  background: #ED4A23; 
}

#form-contain-btn{
  display: block;
  text-align: right;
}

.input-error{
  color: red;
  font-size: 13px;
  padding: 2px 0;
  position: absolute;
  margin-top: 4rem;
  font-weight: 400;
  font-style: italic;
  -webkit-animation-name: error;
          animation-name: error;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  font-family: 'Montserrat';
}

@-webkit-keyframes error{
  from{
    text-shadow: none;
  }
  to{
    text-shadow: 1px 1px 7px black;
  }
}

@keyframes error{
  from{
    text-shadow: none;
  }
  to{
    text-shadow: 1px 1px 7px black;
  }
}

#comment ~ .input-error{
  margin-top: 6rem;
  padding: 0px 0;
}

@media screen and (max-width: 960px) {
  .form-inputs {
    display: block;
  }
  
  .form-input {
    width: 100%;
  }

  .form-label {
    font-size: 1.2rem;
  }

  .input-error {
    position: initial;
  }
}

@media screen and (max-width: 600px) {
  .form-row {
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
  }    
}
.form-content-success{
    padding: 0.5% 0%;
    width: 75%;
    border-radius: 5px;
}

.form-success-title{
    clear: both;
    font-size: 2.6rem;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0.5px;
    -webkit-animation-name: text-display;
            animation-name: text-display;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.form-success-text{
    font-size: 1.7rem;
    border-radius: 5px;
    text-align: center;
    -webkit-animation-name: text-display;
            animation-name: text-display;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

@-webkit-keyframes text-display{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes text-display{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.footer-icon-pico{
    width: 8%;
}

.i-left{
    float: left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-name: pico-left;
            animation-name: pico-left;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.i-right{
    float: right;
    -webkit-animation-name: pico-right;
            animation-name: pico-right;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;}


@-webkit-keyframes pico-left{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}


@keyframes pico-left{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}

@-webkit-keyframes pico-right{
    from{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    to{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes pico-right{
    from{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    to{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@media screen and (max-width: 820px) {
    .form-content-success {
        width: 100%;
    }
    .form-success-title {
        font-size: 1.5rem;
        padding-top: 10px;
    }
    .form-success-text{
        font-size: 1rem !important;
        padding-bottom: 10px;
    }
    .footer-icon-pico {
        width: 10%;
    }
}
@media screen and (min-width: 1200px) and (max-width:1400px) {
    .form-success-title {
        font-size: 2.1rem;
    }
    .form-success-text {
        font-size: 1.4rem !important;
    }
}
.cards__container {
  display: flex;
  flex-flow: column;
  margin-top: 75px;
}

.cards__items {
  margin-bottom: 10%;
  position: relative;
  grid-gap: 30px;
  gap: 30px;
  -webkit-filter: drop-shadow(5px 5px 5px #bbbbbb);
          filter: drop-shadow(5px 5px 5px #bbbbbb);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}

.cards__item__link {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #d54320;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  background: #f1f1f0;
  border-radius: 8px;
  padding: 0.8rem 1.2rem;
  width: 9rem;
  text-align: center;
  text-decoration: none;
}

.cards__item__link i {
  font-weight: 600;
}

.cards__item__link:hover {
  color: #f1f1f0;
  background: #ed4a23;
  border: 1px solid #f1f1f0;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  max-height: 290px;
  height: 15vw;
  overflow: hidden;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 15px 15px;
  max-width: calc((100%) - 60px);
  font-size: 35px;
  font-weight: bolder;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__video {
  cursor: pointer;
}

.cards__item__zoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: ease-in-out 0.25s;
  cursor: pointer;
  -webkit-animation: zoom-in-out 5s infinite;
          animation: zoom-in-out 5s infinite;
}

@-webkit-keyframes zoom-in-out {
  0% {
    scale: 1;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  50% {
    scale: 1.1;
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  100% {
    scale: 1;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }
}

@keyframes zoom-in-out {
  0% {
    scale: 1;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  50% {
    scale: 1.1;
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  100% {
    scale: 1;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: ease-in-out 0.25s;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  cursor: pointer;
}

.tag {
  position: absolute;
  top: 0;
  -webkit-transform: translate(-15%, -50%);
          transform: translate(-15%, -50%);
  z-index: 100;
}

.cards__item__data {
  display: flex;
  align-items: center;
  margin-left: 0.7vw;
  width: -webkit-max-content;
  width: max-content;
}

.cards__item__data__icon {
  width: 1vw;
  height: 1vw;
  margin-right: 0.2vw;
}

.cards__title__model {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f1f1f0;
  white-space: nowrap;
}

.cards__item__data__text {
  color: #f1f1f0;
  font-size: 0.75rem;
}

.cards__item__line {
  border-bottom: 0px solid #f1f1f0;
  border-color: #f1f1f0;
  box-shadow: none;
  margin: 15px 0;
  border-style: solid;
  width: 100%;
}

.cards__item__line:last-of-type {
  margin-bottom: 5px;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.cards__item__info {
  padding: 1.25rem;
  background: #ed4a23;
  display: block;
  width: auto;
  height: 262px;
}

.cards__item__prince__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.cards__item__prince__item:first-child {
  width: 100%;
}

.cards__item__prince__item img {
  width: 15px;
}

.cards__item__prince__item span {
  font-size: 11px;
  color: #fff;
  width: -webkit-max-content;
  width: max-content;
}

.cards__item__display {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  justify-content: space-between;
}

.cards__item__prince {
  display: flex;
  padding: 2% 0px;
  justify-content: space-between;
}

.cardLine {
  color: #f1f1f0;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
  -webkit-user-select: none;
          user-select: none;
  background-color: #373334;
  width: 60%;
  margin: auto;
  padding: 15px;
  position: relative;
}

.rectangulo {
  height: 54px;
  width: 180px;
  border: 5px solid #d54320;
  position: absolute;
  z-index: -1;
  right: -20px;
  top: -15px;
}

.rectangulo-cabana {
  border: 10px solid #373334;
  height: 84px;
  width: 200px;
  right: -30px;
  top: -30px;
}

.cardLineSubText {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  color: #373334;
  -webkit-user-select: none;
          user-select: none;
  margin-top: 15px;
  margin-bottom: 40px;
}

.lg .lg-img-wrap {
  background: #373334;
}

div.lg-thumb,
div#lg-counter,
span#lg-counter-current,
span#lg-counter-all {
  background: transparent;
  color: #f1f1f0;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #ed4a23;
}

a#lg-download {
  display: none;
}

.cards__item__info__prince {
  color: #f1f1f0;
}

.cards__item__entrega {
  font-size: 15px;
  font-weight: 400;
}

.cards__item__entrega strong {
  font-size: 20px;
}

.cards__item__cuotas {
  font-size: 15px;
  font-weight: 400;
}

.fadeOutImage {
  animation: fadeIn 0.9s ease reverse;
  opacity: 0;
}

.cards__item__display_icon {
  display: flex;
}

.linea-cabana {
  background-color: #E64C2A;
  justify-content: flex-end;
  position: relative;
  padding-right: 65px;
  width: 85% !important;
  font-size: 2.35vw;
}

.oferta-lanzamiento {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 20px;
  -webkit-transform: translateY(-30%);
          transform: translateY(-30%);
}

.contenedor-complejo {
  display: grid;
  grid-template-columns: 1fr 32%;
}

.contenedor-complejo {
  background-color: #E54B2A;
}

.contenedor-complejo img {
  height: 100%;
  object-fit: cover;
}

.contenedor-complejo__enunciado {
  padding: 0 33px;
  padding-top: 31px;
}

.contenedor-complejo__enunciado h1 {
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44.6701px;
  line-height: 57px;
  text-transform: uppercase;
  margin-bottom: 18px;

  color: #F1F1F0;
}


.contenedor-complejo__enunciado p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  padding-top: 20px;
  border-top: 2px solid #F2F0F2;
}

.contenedor-complejo__enunciado__box {
  color: #000;
  background-color: #fff;
  box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  -webkit-box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  -moz-box-shadow: 6px -7px 0px 0px rgba(55, 51, 52, 1);
  padding: 8px 15px;
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px);
  margin-top: 43px;
}

.contenedor-complejo__enunciado__box p {
  color: #000;
  padding: 0;
  border: none;
}

.items__complejo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 43px;
}

.cardLineSubText-cabana {
  margin-top: 67px;
  margin-bottom: 90px;
}

@media screen and (max-width: 1600px) {
  .cards__item__link {
    display: flex;
    width: 8rem;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .cards__item__info {
    height: 255px;
  }

  .oferta-lanzamiento {
    width: 370px;
    top: -10px;
    left: 3%;
  }

  .linea-cabana {
    justify-content: center;
    margin-top: 78px;
    padding-right: 0;
  }

  .cards__item__info {
    height: 290px;
  }
}

@media screen and (max-width: 1500px) {
  .cards__item__pic-wrap {
    height: 17rem;
  }

  .cards__item__info {
    height: 290px;
  }

}

@media screen and (max-width: 1300px) {
  .cards__item__pic-wrap {
    height: 15rem;
  }
}

@media screen and (max-width: 1200px) {
  .cards__title__model {
    font-size: 1.25rem;
  }

  .cards__item__entrega {
    display: flex;
    flex-direction: column;
  }

  .cards__item__info {
    height: 310px;
  }
}

@media screen and (max-width: 1150px) {
  .cards__item__link {
    padding: 0.8rem 0.2rem;
    width: 8rem;
  }

  .cards__item__pic-wrap {
    height: 13rem;
  }


}

@media screen and (max-width: 1000px) {
  .cards {
    padding: 0rem;
  }

  .cabanas-items {
    padding: 38px 27px;
  }

  .cards__item__pic-wrap {
    width: 25rem;
    height: 60rem;
  }

  .cards__item__data__text {
    font-size: 1rem;
  }

  .cards__item__data__icon {
    width: 1.4rem;
    height: 2rem;
    margin-right: 1.5vw;
  }

  .cards__item {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 50px;
    margin: 2rem 0px;
    display: block;
    width: 100%;
    position: relative;
  }

  .cardLineSubText-cabana {
    margin-top: 34px;
    margin-bottom: 26px;
  }

  .cards__items {
    display: flex;
    overflow-x: auto;
  }

  .cards__item__entrega {
    display: flex;
    flex-direction: row;
  }

  .cards__item__entrega strong {
    margin-left: 0.25rem;
  }

  .cardLine {
    width: 75%;
  }

  .contenedor-complejo {
    grid-template-columns: auto;
  }

  .oferta-lanzamiento {
    width: 180px;
    top: 75px;
    left: 30px;
  }

  .linea-cabana {
    margin-top: 115px;
  }

  .contenedor-complejo__enunciado {
    padding: 25px 18px;
  }

  .contenedor-complejo__enunciado h1 {
    font-size: 24px;
    margin: 0;
  }

  .contenedor-complejo__enunciado p:first-of-type {
    padding-top: 10px;
  }

  .contenedor-complejo__enunciado p:nth-child(1) {
    padding-top: 0;
  }

  .items__complejo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }

  .linea-cabana {
    font-size: 2.4vw;
    justify-content: center;
  }

  .rectangulo-cabana {
    border: 8px solid #373334;
    height: 60px;
    width: 160px;
    right: -20px;
    top: -18px;
  }

  .cards__item__info {
    height: 270px;
  }
}

@media screen and (max-width: 450px) {
  .cards__container {
    margin-top: 50px;
  }

  .cardLineSubText-cabana {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .cards__title__model {
    font-size: 1.6rem;
  }

  .cards__item__pic-wrap {
    width: 100%;
    height: 12.6rem;
  }

  .cards__item__display {
    flex-direction: column;
    align-items: center;
  }

  .cards__item__prince {
    flex-direction: column;
    align-items: center;
  }

  .cards__item__info__prince {
    text-align: center;
  }

  .cards__item__info {
    width: 17rem;
    height: 400px;
  }

  .cards__item__prince__item:last-of-type {
    margin-top: 8px;
  }

  .cards__item__link {
    margin-top: 1rem;
    padding: 0.8rem 2.5rem;
    width: 11rem;
  }

  .cardLine {
    font-size: 6vw;
    width: 75%;
  }

  .cardLineSubText {
    font-size: 4vw;
    margin: 0;
    margin-top: 10px;
  }

  .cardLineSubText-cabana {
    margin-top: 28px;
    margin-bottom: 26px;
  }

  .cards__item__data {
    margin-left: 4vw;
  }

  .rectangulo {
    width: 120px;
  }

  .linea-cabana {
    font-size: 3.4vw;
    justify-content: center;
  }

  .cards__item__prince__item:first-child {
    width: initial;
  }

}
.carousel_shadow {
  -webkit-filter: drop-shadow(5px 5px 10px #D4D4D4);
          filter: drop-shadow(5px 5px 10px #D4D4D4);
}

.carousel .control-dots .dot.selected {
  background: #ed4a23;
  outline: none;
}

.carousel.carousel-slider {
  /* margin-top: 100px; */
  /* margin: 100px 0px 0px 0px!important; */
  background-color: #f1f1f0;
}

.slider-redes {
  width: 20%;
  position: absolute;
  bottom: 10%;
  left: 5%;
  z-index: 2;
}

li.slider {
  text-decoration: none;
  outline: none;
}

.slider video {
  width: 100%;
  height: auto;
  outline: none;
  border: none;
  text-decoration: none;
}

.slider-info {
  color: #ed4a23;
  font-size: 2rem;
  font-weight: 700;
  outline: none;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.slider-button {
  color: #ffffff;
  background: #ed4a23;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  font-size: 1.5rem;
  padding: 5px 15px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  z-index: 5;
}

.slider-icon-redes {
  margin-top: 2rem;
  padding-left: 9%;
}

.slider-icon-redes a {
  color: #ed4a23;
  font-size: 1.5rem;
  text-decoration: none;
  outline: none;
  margin-right: 10%;
}

.carousel-slider .control-dots {
  bottom: 15px !important;
}

@media screen and (max-width: 960px) {
  .slider-redes {
    width: 100%;
    position: absolute;
    display: flex;
    z-index: 2;
    bottom: 0%;
    top: 28%;
    left: 10%;
  }

  .slider-info {
    font-size: 1.5rem;
  }

  .slider-button {
    font-size: 1.2rem;
    padding: 5px 12px;
  }

  .slider-icon-redes {
    display: none;
  }

  .carousel .control-dots {
    margin: 5px 0;
  }

  .carousel-slider .control-dots {
    bottom: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .slider-info {
    font-size: 1rem;
  }
}
.contenedor {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor-contenido {
  width: 80%;
  max-width: 590px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagen {
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .imagen {
    width: 80%;
  }
}

.titulo {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(22px, 5vw, 31px);
  line-height: 46px;
  text-align: center;
}

.parrafo {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(14px, 3vw, 16px);
  line-height: 24px;
  text-align: center;
  margin-bottom: 90px;
}

.button {
  background-color: #ed4a23;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  font-weight: 600;
  font-size: clamp(18px, 5vw, 24px);
  line-height: 36px;
  text-align: center;
  padding: clamp(4px, 2vw, 10px) clamp(18px, 5vw, 26px);
  transition: 0.1s all;
}

.button:hover {
  background-color: #fff;
  color: #ed4a23;
  border: 1px solid #ed4a23;
  transition: 0.25s all;
}

.button:active {
  background-color: #fff;
  color: #ed4a23;
  border: 1px solid #ed4a23;
}

