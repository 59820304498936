::selection {
  background: #f1f1f0;
  color: #ed4a23;
}

.wrapper {
  padding-right: 15vw;
  padding-left: 15vw;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

body {
  background-color: #f1f1f0;
}

/* @media screen and (min-width: 900px) {
  img.lg-object.lg-image {
    padding: 8rem;
  }
} */

@media screen and (max-width: 1500px) {
  .wrapper {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}
